<template>
  <div class="event-form" v-if="all_services.length !== 0">
    <Button class="btn-close loading-btn" @click="$emit('onclose')">
      <i class="pi pi-times"></i>
    </Button>
    <div class="tab-pane">
      <div class="tab-item" :class="{ activeTab: tab === 1 }" @click="tab = 1">
        {{ i18n.$t('Detail') }}
      </div>
      <div
        class="tab-item"
        v-if="rdv == null"
        :class="{ activeTab: tab === 2 }"
        @click="tab = 2"
      >
        {{ i18n.$t('Contact') }}
      </div>
    </div>
    <div class="tab-content">
      <form v-show="tab === 1" ref="eventForm">
        <h5 class="font-semibold p-mb-0">
          {{ i18n.$t('Add') }} {{ event._type }}
        </h5>
        <div class="form-input">
          <input type="hidden" name="_type" v-model="event._type" />
        </div>

        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Evenement') }}</label>
          <Dropdown
            name="icon"
            v-model="event._type"
            :options="types"
            optionLabel="label"
            optionValue="value"
          />
        </div>

        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Name') }}</label>
          <InputText type="text" name="name" v-model="event.name" required />
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Select Service') }}</label>
          <Dropdown
            name="service"
            v-model="event.service_id"
            :options="all_services"
            optionLabel="name"
            optionValue="_id"
            @change="getEmployees(event.service_id)"
            required
          />
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Select Contact') }}</label>
          <MultiSelect
            name="contact"
            v-model="event.__contact_id"
            :options="contacts"
            optionLabel="first_name"
            optionValue="_id"
            required
          />
        </div>

        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Select Employee') }}</label>
          <Dropdown
            name="employee"
            v-model="event.__employee_id"
            :options="employees"
            optionLabel="first_name"
            optionValue="id"
            required
          />
        </div>
        <div v-if="event._type != 'Rdv'">
          <div class="action">
            <div class="form-input p-mt-3">
              <label for="time24"
                >{{ i18n.$t('Date') }}/{{ i18n.$t('Time') }}</label
              >
              <Calendar
                id="date"
                :inline="true"
                name="date"
                :modelValue="event.date"
                v-model="event.date"
                :showTime="false"
                :showSeconds="false"
                :minDate="new Date()"
              />
            </div>

            <div class="form-input p-mt-3">
              <TimeSelect
                :modelValue="event.date"
                @update="updateTime($event)"
              />
            </div>
          </div>

          <div class="form-input">
            <input type="hidden" name="_status" v-model="event._status" />
          </div>
          <div class="form-input p-mt-3">
            <label>{{ i18n.$t('Expire at') }} </label>
            <Calendar
              id="expires_at"
              v-model="event.expires_at"
              :showTime="false"
              :showSeconds="false"
              :minDate="new Date()"
            />
          </div>
          <div class="form-input p-mt-3">
            <label class="p-mb-2">{{ i18n.$t('Remind') }}</label>
            <reminder :reminder="event.reminder" />
          </div>
        </div>
        <div v-if="event._type == 'Rdv'" class="p-mt-3">
          <label>{{ i18n.$t('Date') }}/{{ i18n.$t('Time') }}</label>
          <rdv-slots :event="event" />
        </div>
        <!-- <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Duration') }}</label>
          <InputText
            type="text"
            name="duration"
            class="border border-teal-500 px-2 py-1 rounded"
            v-model="event.duration"
          />
        </div> -->
        <!-- 
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Icon') }}</label>
          <Dropdown
            name="icon"
            v-model="event.icon"
            :options="icons"
            optionLabel="label"
            optionValue="value"
          />
        </div> -->
        <input type="hidden" name="owner_id" v-model="event.owner_id" />
      </form>
      <form v-if="rdv == null" v-show="tab === 2" ref="contactForm">
        <div class="form-input">
          <p>{{ i18n.$t('First Name') }}</p>
          <InputText v-model="contact.first_name" required />
        </div>
        <div class="form-input p-mt-3">
          <p class="text">{{ i18n.$t('Last Name') }}</p>
          <InputText v-model="contact.last_name" required />
        </div>
        <div class="form-input p-mt-3">
          <p>{{ i18n.$t('Email') }}</p>
          <InputText type="email" v-model="contact.email" required />
        </div>
        <div class="form-input p-mt-3">
          <p>{{ i18n.$t('Phone') }}</p>
          <input-phone
            v-model="contact.phone"
            required
            type="text"
            placeholder="Phone Number"
            name="phone"
          />
        </div>
        <input type="hidden" name="owner_id" v-model="contact.owner_id" />
      </form>
      <div class="modal-footer">
        <div class="p-mt-4">
          <transition-group name="p-messages" tag="div">
            <Message
              v-for="msg of message"
              :severity="msg.severity"
              :key="msg.content"
            >
              {{ msg.content }}
            </Message>
          </transition-group>
        </div>
      </div>
    </div>
    <Button type="submit" class="btn-save loading-btn" @click="onSave">
      <spinner v-if="isLoading" color="white" />
      <span v-if="!isLoading">{{ i18n.$t('Save') }}</span>
    </Button>
  </div>
  <div class="event-form" v-else>
    <Card class="tab-content error-content">
      <template #header>
        <img alt="user header" src="/assets/layout/images/usercard.png" />
      </template>
      <template #title>
        {{ i18n.$t('No service available') }}
      </template>
      <template #content>
        {{ i18n.$t('Create service') }}
      </template>
      <template #footer>
        <Button
          icon="pi pi-check"
          @click="servicePage"
          label="Configurer mes services"
        />
        <Button
          icon="pi pi-times"
          @click="$emit('onclose')"
          label="Annuler"
          class="p-button-secondary"
          style="margin-left: 0.5em"
        />
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue';

import Spinner from '@/components/UI/Spinner.vue';
import axios from '@/plugins/axios';
import useI18n from '@/plugins/useI18n';

import {
  GET_SERVICES,
  GET_CONTACTS,
  GET_LANGUAGE,
  GET_USERDATA,
  GET_EMPLOYEES,
  FETCH_CONTACTS
} from '@/store/types';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { usePrimeVue } from 'primevue/config';
import TimeSelect from './TimeSelect';
import EventModel from '@/models/event';
import ContactModel from '@/models/contact';
import Reminder from '@/components/Event/Reminder.vue';
import { useToast } from 'primevue/usetoast';
import RdvSlots from './RdvSlots.vue';

export default {
  components: {
    Spinner,
    TimeSelect,
    Reminder,
    RdvSlots
  },
  props: {
    contacts: {
      type: Array,
      default: () => []
    },
    selectedDate: {
      default: () => ''
    },
    rdv: {
      type: Object,
      default: () => null
    }
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    /*
    initData();

    async function initData() {
      //await store.dispatch(FETCH_GROUPS, userData.value.id);
      await store.dispatch(FETCH_EMPLOYEES, userData.value.organisation_id);
    }
    */

    const all_services = computed(() => store.getters[GET_SERVICES]);

    const contacts = computed(() => store.getters[GET_CONTACTS]);
    const allEmployees = computed(() => store.getters[GET_EMPLOYEES]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    var contactData =
      contacts &&
      contacts.value &&
      contacts.value.map((i, k) => {
        return i._id;
      });
    const eventData =
      props.rdv != null
        ? props.rdv
        : { _type: 'Rdv', date: props.selectedDate };
    const event = reactive(new EventModel(eventData));

    const contact = reactive(new ContactModel());
    const employees = ref([]);

    const isLoading = ref(false);
    const message = ref([]);
    const icons = [
      { label: 'Icon1', value: 'icon1' },
      { label: 'Icon2', value: 'icon2' },
      { label: 'Icon3', value: 'icon3' }
    ];

    const types = [
      { label: 'Rdv', value: 'Rdv' },
      { label: 'Task', value: 'Task' },
      { label: 'Event', value: 'Event' }
    ];

    async function getEmployees(serviceId) {
      employees.value = [];
      allEmployees.value.forEach((v) => {
        for (const service of v.services) {
          if (service.id == serviceId) {
            employees.value.push(v);
            continue;
          }
        }
      });
    }
    if (props.rdv != null) {
      getEmployees(event.service_id);
    }
    const eventForm = ref();
    const tab = ref(1);

    changeLang();

    function changeLang() {
      const primevue = usePrimeVue();
      primevue.config.locale.dayNamesMin = [
        i18n.$t('Sun'),
        i18n.$t('Mon'),
        i18n.$t('Tue'),
        i18n.$t('Wed'),
        i18n.$t('Thu'),
        i18n.$t('Fri'),
        i18n.$t('Sat')
      ];
      primevue.config.locale.monthNames = [
        i18n.$t('January'),
        i18n.$t('February'),
        i18n.$t('March'),
        i18n.$t('April'),
        i18n.$t('May'),
        i18n.$t('June'),
        i18n.$t('July'),
        i18n.$t('August'),
        i18n.$t('September'),
        i18n.$t('October'),
        i18n.$t('November'),
        i18n.$t('December')
      ];
    }

    function onSave() {
      /*
      let indexService;

      all_services.value.forEach((el, index) => {
        if (el._id == event.service_id) {
          indexService = index;
        }
      });
      */

      if (tab.value === 1) {
        if (event._type != 'Rdv') {
          event.date.setHours(hourSelect.value);
          event.date.setMinutes(minuteSelect.value);
        }
        onSaveRDV();
      }
      if (tab.value === 2) {
        onSaveContact();
      }
    }

    async function onSaveRDV() {
      isLoading.value = true;
      try {
        if (props.rdv != null) {
          // console.log('update', props.rdv, event);
          // return;
          const res = await axios.put('event/' + props.rdv._id, {
            ...event
          });
          emit('updateschedule', res.data.data);
        } else {
          const res = await axios.post('event', {
            ...event
          });
          emit('addschedule', res.data.data.items[0]);
        }
        toast.add({
          severity: 'success',
          summary: 'Saved successfully',
          detail: 'Event saved',
          life: 3000
        });
      } catch (error) {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong' }
        ];
      }
      isLoading.value = false;
    }

    async function onSaveContact() {
      isLoading.value = true;
      message.value = [];
      try {
        await axios.post('contact', contact);
        toast.add({
          severity: 'success',
          summary: 'Saved successfully',
          detail: 'Contact saved',
          life: 3000
        });
        store.dispatch(FETCH_CONTACTS, userData.value.id);
        emit('onclose');
      } catch {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong' }
        ];
      }
      isLoading.value = false;
    }

    function servicePage() {
      router.push({ path: '/service/add' });
    }
    const hourSelect = ref(event.date.getHours());
    const minuteSelect = ref(event.date.getMinutes());

    function updateTime(e) {
      hourSelect.value = e.hours;
      minuteSelect.value = e.minutes;
    }

    return {
      i18n,
      eventForm,
      event,
      contact,
      icons,
      types,
      isLoading,
      message,
      tab,
      onSave,
      all_services,
      getEmployees,
      servicePage,
      employees,
      updateTime
    };
  }
};
</script>
<style lang="scss" scoped>
.action {
  display: flex;
  align-items: center;
}
label {
  margin-bottom: 0.25rem;
}
.event-form {
  width: 600px;
  max-width: 100%;
  background: #ffffff;
}
// ::v-deep(.p-dialog) {
//   .p-dialog-header, .p-dialog-content {
//     display: none;
//   }
// }
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    margin-bottom: 0.1rem;
  }
}
.p-calendar {
  max-width: 360px;
}
.loading-btn {
  width: 120px;
  height: 40px;
}
.radio-input label {
  margin-left: 10px;
}
.tab-pane {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  background: #0388e5;
  .tab-item {
    color: #ffffff;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
    &.activeTab {
      border-bottom: 2px solid #ffffff;
      font-weight: bold;
    }
  }
}
.tab-content {
  padding: 20px 30px 30px 30px;
  height: calc(100vh - 200px);
  max-height: 100%;
  position: relative;
  overflow-y: auto;
}
.modal-footer {
  position: absolute;
}
.loading-btn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.error-content p span {
  color: #3b82c6;
  text-decoration: underline;
  cursor: pointer;
}
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: 0;
  width: 40px;
  height: 40px;
  border: none;
  .pi-times {
    font-size: 24px;
    color: #fff;
  }
}
.btn-save {
  margin-left: auto;
  bottom: 15px;
  right: 20px;
}
</style>
<style>
#tui-time-picker-container-rdv .tui-timepicker {
  padding: 0 !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
}
.tui-timepicker-btn-area .tui-timepicker-spinbox-input {
  border: 1px solid rgb(170 170 170);
  border-right: none;
  width: 70%;
}
.tui-timepicker-btn-up {
  display: none;
}
.tui-timepicker-btn-area {
  height: 30px;
  padding: 0;
}
.tui-timepicker-btn-down {
  width: 50%;
  height: 30px;
  left: initial;
  right: -15px;
  border: 1px solid rgb(170 170 170);
}
.tui-timepicker-hour .tui-timepicker-btn-area {
  left: -18px;
}
.p-d-flex .tui-datepicker {
  height: 230px;
  overflow-y: scroll;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  margin: 0 auto;
}
.p-dialog .p-dialog-content {
  overflow: hidden;
  max-height: 100vh;
}
.p-dialog .p-dialog-header {
  display: none;
}
.p-dialog .p-dialog-content {
  padding: 0;
}
</style>
