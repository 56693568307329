<template>
  <div class="event-form">
    <i
      class="pi pi-times p-p-5"
      style="float: right"
      @click="$emit('onclose')"
    ></i>
    <h4 class="">{{ i18n.$t('Content of') }} "{{ rdv.name }}"</h4>
    <div class="tab-content">
      <div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Name') }}</label>
          <p>
            {{ rdv.name }}
          </p>
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Type') }}</label>
          <p>
            {{ rdv._type }}
          </p>
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Date') }}</label>
          <p name="date">
            {{ rdv_date }}
          </p>
        </div>
        <div class="form-input p-mt-3" v-if="rdv._type != 'Rdv'">
          <label>{{ i18n.$t('Expire at') }}</label>
          <p name="date">
            {{ rdv_expire_date }}
          </p>
        </div>
        <!-- <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Duration') }}</label>
          <p
            name="duration"
            class="border border-teal-500 px-2 rounded rdv-value"
          >
            {{ rdv.duration }}
          </p>
        </div> -->
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Service') }}</label>
          <p>
            {{ service.name }}
          </p>
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Employee') }}</label>
          <p>{{ employee.first_name }} {{ employee.last_name }}</p>
        </div>
        <div class="form-input p-mt-3">
          <label>{{ i18n.$t('Contacts') }}</label>
          <p>
            {{ rdv.contact }}
          </p>
          <div v-if="rdv.__contact_id && rdv.__contact_id.length > 0">
            <li v-for="item in rdv.__contact_id" :key="item" class="px-4">
              {{ contacts[item] }}
            </li>
          </div>
          <span v-else>_</span>
        </div>

        <div class="form-input p-mt-3" v-if="rdv._type != 'Rdv'">
          <label>{{ i18n.$t('Remind') }}</label>
          <reminder :reminder="rdv.reminder" :change="false" />
        </div>
        <div class="p-flex flex-row p-mt-6">
          <Button class="p-button-secondary" @click="$emit('onclose')">
            {{ i18n.$t('Close') }}
          </Button>
          <Button @click="generateICS" class="p-ml-2 p-button-help">
            {{ i18n.$t('Get ICS') }}</Button
          >
          <Button
            @click="$emit('openEditEvent')"
            class="p-ml-2 p-button-primary"
          >
            {{ i18n.$t('Edit') }}
          </Button>
          <Button @click="onDelete" class="p-ml-2 p-button-danger">
            {{ i18n.$t('Delete') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import useI18n from '@/plugins/useI18n';
import { GET_CONTACTS, GET_EMPLOYEES, GET_SERVICES } from '../../store/types';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import axios from '@/plugins/axios';
import Reminder from '../Event/Reminder.vue';

const ical = require('ical-generator');
export default {
  components: { Reminder },
  props: {
    rdv: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { i18n } = useI18n();
    const store = useStore();
    const services = computed(() => store.getters[GET_SERVICES]);
    const message = ref([]);
    const isLoading = ref(false);
    const toast = useToast();

    const rdv_date = moment.utc(props.rdv.date).format(i18n.$t('date_format'));
    const rdv_expire_date = moment.utc(props.rdv.expires_at).format(
      i18n.$t('date_format')
    );

    let service = { name: '_' };
    services.value.forEach((v) => {
      if (v._id == props.rdv.service_id) {
        service = v;
      }
    });

    const allEmployees = computed(() => store.getters[GET_EMPLOYEES]);
    let employee = { name: '_' };
    allEmployees.value.forEach((v) => {
      if (v.id == props.rdv.__employee_id) {
        employee = v;
      }
    });

    const contacts = computed(() => {
      let result = {};
      store.getters[GET_CONTACTS].forEach((v) => {
        result[v._id] = v.first_name + ' ' + v.last_name;
      });
      return result;
    });

    function generateICS() {
      const cal = ical({ domain: 'github.com', name: 'my first iCal' });
      cal.createEvent({
        start: moment(props.rdv.date),
        end: moment(props.rdv.date).add(Number(props.rdv.duration0, 'minute')),
        summary: props.rdv.name,
        description: 'It works ;)',
        location: 'my room',
        url: 'http://sebbo.net/'
      });
      window.open('data:text/calendar;charset=utf8,' + escape(cal.toString()));
    }
    async function onDelete() {
      isLoading.value = true;
      try {
        //console.log('delete', props.rdv, event);
        // return;
        const res = await axios.delete('event/' + props.rdv._id);
        emit('deleteEvent', res.data.data);
        toast.add({
          severity: 'info',
          summary: 'Removed successfully',
          detail: 'Event removed',
          life: 3000
        });
      } catch (error) {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong' }
        ];
        console.log(error);
      }
      isLoading.value = false;
    }
    return {
      i18n,
      generateICS,
      service,
      contacts,
      employee,
      rdv_date,
      rdv_expire_date,
      onDelete
    };
  }
};
</script>

<style lang="scss" scoped>
.event-form {
  width: 420px;
  max-width: 100%;
  background: #ffffff;
  padding: 20px;
  height: 90vh;
  overflow-y: auto;
}
.form-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
}
.loading-btn {
  width: 120px;
  height: 40px;
}
.radio-input label {
  margin-left: 10px;
}
.tab-content {
  width: 100%;
  padding: 10px;
}
.rdv-value {
  height: 30px;
  display: flex;
  align-items: center;
}
</style>
