<template>
  <div class="p-grid">
    <div class="p-col-12 transparent">
      <div class="card transparent">
        <div class="rdv-calendar text-left transparent">
          <div class="event-calendar transparent">
            <div class="calendar-header transparent">
              <div
                class="
                  calendar-date-absolute calendar-date
                  p-d-flex p-ai-center
                "
              >
                <Button
                  class="calendar-header-btn p-mr-1"
                  @click="onPrevPeriod"
                >
                  <i class="pi pi-arrow-down"></i>
                </Button>
                <h5 class="p-mx-3 p-my-0 p-text-bold">
                  <!-- {{ i18n.$t(dateShow) }} {{ dateShowYear }} -->
                  {{ dateShow }}
                </h5>

                <Button
                  class="calendar-header-btn p-mr-1"
                  @click="onNextPeriod"
                >
                  <i class="pi pi-arrow-up"></i>
                </Button>
              </div>
              <div class="calendar-date-right">
                <Button
                  class="calendar-header-btn p-mr-1"
                  @click="onClickTodayBtn()"
                >
                  <i class="pi pi-calendar-minus"></i>
                  {{ i18n.$t('Today') }}
                </Button>

                <Button
                  v-model="calendarMode"
                  class="calendar-header-btn p-mr-1"
                  @click="onClickView('day')"
                >
                  <i class="pi pi-calendar"></i>
                  {{ i18n.$t('Day') }}
                </Button>

                <Dropdown
                  v-model="calendarMode"
                  :options="calendarModeSelectWeek"
                  optionLabel="label"
                  optionValue="value"
                  class="calendar-types calendar-header-btn p-mr-3"
                  @change="onChangeView"
                  placeholder="Week"
                />

                <Button
                  v-model="calendarMode"
                  class="calendar-header-btn p-mr-1"
                  @click="onClickView('month')"
                >
                  <i class="pi pi-calendar"></i>
                  {{ i18n.$t('Month') }}
                </Button>
              </div>
            </div>
            <div id="calendar" class="calendar"></div>
          </div>
          <Dialog v-model:visible="showRDVForm" :modal="true">
            <RDVFormDlg
              v-if="showRDVForm"
              :contacts="contacts"
              :selectedDate="selectedDate"
              :rdv="selectedEvent"
              @addschedule="addSchedule"
              @updateschedule="updateSchedule"
              @onclose="deleteSelected(), (showRDVForm = false)"
            />
          </Dialog>
          <Dialog v-model:visible="showRDVView" :modal="true">
            <RDVViewDlg
              :rdv="selectedEvent"
              @onclose="(showRDVView = false), (selectedEvent = null)"
              @openEditEvent="openEditEvent()"
              @deleteEvent="updateSchedule(), (showRDVView = false)"
              :style="{ width: '50vw' }"
            />
          </Dialog>
          <BlockUI :blocked="blockUI" :fullScreen="true">
            <ProgressSpinner
              v-if="blockUI"
              style="position: fixed; top: 50%; left: 50%"
            />
          </BlockUI>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Calendar from 'tui-calendar';
import moment from 'moment';
import axios from '@/plugins/axios';
import useI18n from '@/plugins/useI18n';
import { addMinutesToDate } from '@/plugins/utility';
import RDVFormDlg from '@/components/Calendar/RDVFormDlg.vue';
import RDVViewDlg from '@/components/Calendar/RDVViewDlg.vue';
import { emitter } from '../../main';
import {
  FETCH_EVENTS,
  GET_CALENDAR_EVENTS,
  FETCH_CONTACTS,
  GET_CONTACTS,
  FETCH_EVENT_BY_ID,
  SET_ADD_EVENT,
  GET_SERVICES,
  FETCH_ALL_SERVICES,
  GET_USERDATA
} from '@/store/types';
import { useRoute } from 'vue-router';
import { defaultValues } from '../../store/data/schedule';

export default {
  components: {
    RDVFormDlg,
    RDVViewDlg
  },
  setup() {
    const store = useStore();
    const { i18n } = useI18n();
    const route = useRoute();
    const userData = computed(() => store.getters[GET_USERDATA]);

    const sideBarReduced = ref(false);
    const showRDVForm = ref(false);
    const selectedDate = ref('');
    const showRDVView = ref(false);
    const dateShow = ref();
    store.dispatch(FETCH_CONTACTS, userData.value.id);
    store.dispatch(FETCH_ALL_SERVICES, userData.value.id);
    const services = computed(() => store.getters[GET_SERVICES]);
    const contacts = computed(() => store.getters[GET_CONTACTS]);
    const selectedEvent = ref({});
    const blockUI = ref(true);
    const selectedView = ref('month');
    const dateShowYear = ref();
    // const dateShowMonth = ref();
    dateShowYear.value = moment().format('MMMM YYYY');
    let focusedDate = moment().format('YYYY MM DD');
    const monthTranslate = moment().format('MMMM');
    const YearTranslate = moment().format('YYYY');
    dateShow.value = i18n.$t(monthTranslate) + ' ' + YearTranslate;
    // let calendar = new Calendar();
    let calendar;

    const WEEK_DAYS = [
      i18n.$t('Sunday'),
      i18n.$t('Monday'),
      i18n.$t('Tuesday'),
      i18n.$t('Wednesday'),
      i18n.$t('Thursday'),
      i18n.$t('Friday'),
      i18n.$t('Saturday')
    ];
    const filters = ref({ service: null, employee: null });
    onMounted(async () => {
      emitter.on('add-tui-event', () => onShowRDVForm());
      emitter.on('calendar-date-change', (val) => {
        onDateChange(val);
        updateBGCell(val.getDate());
      });
      emitter.on('updateFilters', (e) => {
        filters.value = e;
        const filtered = updateFilterService(e.service, schedules.value);
        updateFilterEmployee(e.employee, filtered);
      });
      await store.dispatch(FETCH_EVENTS, {
        userId: userData.value.id,
        type: 'All'
      });
      const schedules = computed(() => store.getters[GET_CALENDAR_EVENTS]);

      const settings = await axios.get(`setting?owner_id=${userData.value.id}`);
      const defaults = defaultValues();
      const days = {
        Mon: 0,
        Tue: 1,
        Wed: 2,
        Thu: 3,
        Fri: 4,
        Sat: 5,
        Sun: 6
      };
      let firstDay = defaults.first_day;
      let lastDay = defaults.last_day;
      const settingData = settings.data.data.items;
      let firstHour = settingData.filter((el) => el.type == 'first_hour')[0];
      let lastHour = settingData.filter((el) => el.type == 'last_hour')[0];
      firstHour = firstHour ? firstHour.value : defaults.first_hour;
      lastHour = lastHour ? lastHour.value : defaults.last_hour;
      firstDay = settingData.filter((el) => el.type == 'first_day')[0];
      lastDay = settingData.filter((el) => el.type == 'last_day')[0];
      firstDay = firstDay ? firstDay.value : defaults.first_day;
      lastDay = lastDay ? lastDay.value : defaults.last_day;

      // register templates -----------------------------------------------

      function getTimeTemplate(schedule, isAllDay) {
        var html = [];

        if (!isAllDay) {
          html.push(
            '<strong>' +
              moment(schedule.start.getTime()).format('HH:mm') +
              '</strong> '
          );
        }
        if (schedule.isPrivate) {
          html.push('<span class="calendar-font-icon ic-lock-b"></span>');
          html.push(' Private');
        } else {
          if (schedule.isReadOnly) {
            html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
          } else if (schedule.recurrenceRule) {
            html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
          } else if (schedule.attendees.length) {
            html.push('<span class="calendar-font-icon ic-user-b"></span>');
          } else if (schedule.location) {
            html.push('<span class="calendar-font-icon ic-location-b"></span>');
          }
          html.push(' ' + schedule.title);
        }

        return html.join('');
      }

      function getGridTitleTemplate(type) {
        var title = '';

        switch (type) {
          case 'milestone':
            title =
              '<span class="tui-full-calendar-left-content">MILESTONE</span>';
            break;
          case 'task':
            title = '<span class="tui-full-calendar-left-content">TASK</span>';
            break;
          case 'allday':
            title =
              '<span class="tui-full-calendar-left-content">ALL DAY</span>';
            break;
        }

        return title;
      }

      function getGridCategoryTemplate(category, schedule) {
        var tpl;

        switch (category) {
          case 'milestone':
            tpl =
              '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' +
              schedule.bgColor +
              '">' +
              schedule.title +
              '</span>';
            break;
          case 'task':
            tpl = '#' + schedule.title;
            break;
          case 'allday':
            tpl = getTimeTemplate(schedule, true);
            break;
        }

        return tpl;
      }

      function getPadStart(value) {
        // value = value.toString();
        // return padStart.call(value, 2, '0');
      }

      var templates = {
        weekDayname: function (model) {
          return (
            '<span class="tui-full-calendar-dayname-date">' +
            model.date +
            '</span>&nbsp;&nbsp;<span class="tui-full-calendar-dayname-name">' +
            model.dayName +
            '</span>'
          );
        },
        timezoneDisplayLabel: function (timezoneOffset, displayLabel) {
          var gmt, hour, minutes;

          if (!displayLabel) {
            gmt = timezoneOffset < 0 ? '-' : '+';
            hour = Math.abs(parseInt(timezoneOffset / 60, 10));
            minutes = Math.abs(timezoneOffset % 60);
            displayLabel = gmt + getPadStart(hour) + ':' + getPadStart(minutes);
          }
          return displayLabel;
        },
        weekGridFooterExceed: function (hiddenSchedules) {
          return '+' + hiddenSchedules;
        },
        dayGridTitle: function (viewName) {
          /*
           * use another functions instead of 'dayGridTitle'
           * milestoneTitle: function() {...}
           * taskTitle: function() {...}
           * alldayTitle: function() {...}
           */

          return getGridTitleTemplate(viewName);
        },
        schedule: function (schedule) {
          /*
           * use another functions instead of 'schedule'
           * milestone: function() {...}
           * task: function() {...}
           * allday: function() {...}
           */

          return getGridCategoryTemplate(schedule.category, schedule);
        },
        collapseBtnTitle: function () {
          // ??? 어떤 템플릿인가요??
          return '<span class="tui-full-calendar-icon tui-full-calendar-ic-arrow-solid-top"></span>';
        },
        timegridDisplayTime: function (time) {
          return getPadStart(time.hour) + ':' + getPadStart(time.hour);
        },
        timegridDisplayPrimayTime: function (time) {
          return time.hour + 'h';
        },
        timegridCurrentTime: function (timezone) {
          var templates = [];

          if (!timezone) {
            return '';
          }

          if (timezone.dateDifference !== 0) {
            templates.push(
              '[' +
                timezone.dateDifferenceSign +
                timezone.dateDifference +
                ']<br>'
            );
          }

          templates.push(
            moment(timezone.hourmarker.toUTCString()).format('HH:mm')
          );
          return templates.join('');
        }
      };
      /* ------------------------- */

      var COMMON_CUSTOM_THEME = {
        // month header 'dayname'
        'month.dayname.height': '42px',
        'month.dayname.borderLeft': 'none',
        'month.dayname.paddingLeft': '8px',
        'month.dayname.paddingRight': '0',
        'month.dayname.fontSize': '13px',
        'month.dayname.backgroundColor': 'inherit',
        'month.dayname.fontWeight': 'normal',
        'month.dayname.textAlign': 'left',

        // month day grid cell 'day'
        'month.holidayExceptThisMonth.color': '#f3acac',
        'month.dayExceptThisMonth.color': '#bbb',
        'month.weekend.backgroundColor': '#fafafa',
        'month.day.fontSize': '16px',

        'month.isPastDay.color': 'red',

        // month schedule style
        'month.schedule.borderRadius': '5px',
        'month.schedule.height': '18px',
        'month.schedule.marginTop': '2px',
        'month.schedule.marginLeft': '10px',
        'month.schedule.marginRight': '10px',

        // month more view
        'month.moreView.boxShadow': 'none',
        'month.moreView.paddingBottom': '0',
        'month.moreView.border': '1px solid #9a935a',
        'month.moreView.backgroundColor': '#f9f3c6',
        'month.moreViewTitle.height': '28px',
        'month.moreViewTitle.marginBottom': '0',
        'month.moreViewTitle.backgroundColor': '#f4f4f4',
        'month.moreViewTitle.borderBottom': '1px solid #ddd',
        'month.moreViewTitle.padding': '0 10px',
        'month.moreViewList.padding': '10px'
      };

      calendar = new Calendar('#calendar', {
        defaultView: 'month',
        theme: COMMON_CUSTOM_THEME, // set theme
        taskView: false,
        template: templates,
        scheduleView: ['time'],
        month: {
          narrowWeekend: true // weekend column narrows to 1/2 width
          //workweek: true // show only 5 days except for weekend
        }
      });

      calendar.setOptions({ disableDblClick: true }, true);
      //the firt day of week
      calendar.setOptions({
        month: {
          startDayOfWeek: first_day.value,
          daynames: WEEK_DAYS
          //  workweek: true // show only 5 days except for weekend
        },
        week: {
          startDayOfWeek: first_day.value,
          daynames: WEEK_DAYS,
          hourStart: firstHour,
          hourEnd: lastHour
        }
      });

      blockUI.value = false;

      calendar.on('beforeCreateSchedule', function (e) {
        selectedEvent.value = null;
        const today = calendar.getDate()._date;
        const selectDate = e.start._date;

        if (selectDate > today) {
          selectedDate.value = e.start._date;
          showRDVForm.value = true;
        } else {
          deleteSelected();
        }
      });

      calendar.on('clickDayname', function (date) {});
      calendar.on('clickMore', function (date) {});

      calendar.on('clickSchedule', async (event) => {
        const schedule = event.schedule;
        const rdv = await store.dispatch(FETCH_EVENT_BY_ID, {
          eventId: schedule.calendarId,
          owner_id: userData.value.id
        });
        selectedEvent.value = { ...rdv };
        showRDVView.value = true;
      });
      create(schedules.value);
      pastDays();
      if (typeof route.query.employee != 'undefined') {
        updateFilterEmployee([route.query.employee], schedules.value);
      }
    });

    // function pastDays() {
    //   today.innerHeight
    // }
    function deleteSelected() {
      const items = document.querySelectorAll(
        '.tui-full-calendar-month-guide-block'
      );
      const itemsSelected = document.querySelector('.selected');
      items.forEach((el) => {
        el.style.display = 'none';
      });
      if (itemsSelected) {
        itemsSelected.style.display = 'none';
      }
    }
    function create(schedules) {
      let items = [];
      services.value.forEach((el) => {
        if (el.color) {
          items.push({
            color: el.color,
            id: el._id
          });
        }
      });
      schedules.forEach((el) => {
        items.forEach((i) => {
          if (el.service_id == i.id) {
            el.bgColor = '#' + i.color;
            el.color = 'white';
            el.dragColor = '#' + i.color;
          }
        });
      });

      calendar.createSchedules(schedules);
    }
    function updateBGCell(val) {
      setTimeout(() => {
        const items = document.querySelectorAll(
          '.tui-full-calendar-weekday-grid-line'
        );
        items.forEach((el) => {
          let item = el.querySelector('.tui-full-calendar-weekday-grid-date');
          if (parseInt(item.innerHTML) == val) {
            const today = new Date().getDate();
            if (
              !el.classList.contains('tui-full-calendar-extra-date') &&
              today <= val
            ) {
              el.classList.add('selected');
            }
          }
        });
      }, 100);
    }
    function pastDays() {
      setTimeout(() => {
        const items = document.querySelectorAll(
          '.tui-full-calendar-weekday-grid-line'
        );
        const todayDate = new Date().getDate();
        const todayMonthNumber = moment(new Date()).format('M');
        const currentMonthNumber = moment(calendar.getDate().toDate()).format(
          'M'
        );
        const todayYear = moment(new Date()).format('YYYY');
        const currentYear = moment(calendar.getDate().toDate()).format('YYYY');

        let sunday = document.querySelector(
          '.tui-full-calendar-holiday-sun .tui-full-calendar-dayname-date'
        );
        let sundayColor = document.querySelector(
          '.tui-full-calendar-holiday-sun .tui-full-calendar-dayname-date-area'
        );
        const pastDayColor = '#bbb';

        if (todayYear == currentYear) {
          if (todayMonthNumber > currentMonthNumber) {
            items.forEach((el, index) => {
              let item = el.querySelector(
                '.tui-full-calendar-weekday-grid-date'
              );
              item.style.color = pastDayColor;
            });
            if (sunday) {
              sundayColor.style.color = pastDayColor;
            }
          } else if (todayMonthNumber == currentMonthNumber) {
            items.forEach((el, index) => {
              let item = el.querySelector(
                '.tui-full-calendar-weekday-grid-date'
              );
              if (parseInt(item.innerHTML) < todayDate) {
                item.style.color = pastDayColor;
              }
            });
            if (sunday && parseInt(sunday.innerHTML) < todayDate) {
              sundayColor.style.color = pastDayColor;
            }
          }
        } else {
          items.forEach((el, index) => {
            let item = el.querySelector('.tui-full-calendar-weekday-grid-date');
            item.style.color = pastDayColor;
          });
          if (sunday) {
            sundayColor.style.color = pastDayColor;
          }
        }
      }, 20);
    }

    function selectDate(value) {
      const items = document.querySelectorAll(
        '.tui-full-calendar-weekday-grid-line'
      );
      const oldSelected = document.querySelector('.selected');
      if (oldSelected) {
        oldSelected.classList.remove('selected');
      }
      items.forEach((el, index) => {
        let item = el.querySelector('.tui-full-calendar-weekday-grid-date');
        if (parseInt(item.innerHTML) == value) {
          el.classList.add('selected');
        }
      });
    }
    function onShowRDVForm() {
      selectedEvent.value = null;
      showRDVForm.value = true;
    }
    function openEditEvent() {
      showRDVView.value = false;
      showRDVForm.value = true;
    }

    function onDateChange(val) {
      calendar.setDate(val);
      // focusedDate = moment(val).format('DD/MM/YYYY');
      focusedDate = val;
      getDateShow();
      pastDays();
    }

    function onPrevPeriod() {
      calendar.prev();
      getDateShow();
      pastDays();
    }

    function onNextPeriod() {
      calendar.next();
      getDateShow();
      pastDays();
    }

    function onClickTodayBtn() {
      calendar.today();
      getDateShow();
      pastDays();
    }

    function getDateShow(value) {
      const viewName = calendar.getViewName();
      const calOptions = calendar.getOptions();

      const dateMonthStart = moment(
        calendar.getDateRangeStart().toDate()
      ).format('MMMM');
      const dateDayStart = moment(calendar.getDateRangeStart().toDate()).format(
        'DD'
      );
      const dateYearStart = moment(
        calendar.getDateRangeStart().toDate()
      ).format('YYYY');

      switch (viewName) {
        case 'day':
          {
            dateShow.value = `${dateDayStart} ${i18n.$t(
              dateMonthStart
            )} ${dateYearStart}`;
          }
          break;
        case 'month':
          if (
            calOptions.month.visibleWeeksCount === 2 ||
            calOptions.month.visibleWeeksCount === 3
          ) {
            const dateMonthEnd = moment(
              calendar.getDateRangeEnd().toDate()
            ).format('MMMM');
            const dateDayEnd = moment(
              calendar.getDateRangeEnd().toDate()
            ).format('DD');
            const dateYearEnd = moment(
              calendar.getDateRangeEnd().toDate()
            ).format('YYYY');

            dateShow.value =
              dateDayStart +
              ' ' +
              i18n.$t(dateMonthStart) +
              ' ' +
              dateYearStart +
              ' - ' +
              dateDayEnd +
              ' ' +
              i18n.$t(dateMonthEnd) +
              ' ' +
              dateYearEnd;

            // dateShow.value =
            //   moment(calendar.getDateRangeStart().toDate()).format(
            //     'DD MMMM YYYY'
            //   ) +
            //   ' - ' +
            //   moment(calendar.getDateRangeEnd().toDate()).format(
            //     'DD MMMM YYYY'
            //   );
          } else {
            const middleValue = moment(calendar.getDate().toDate()).format(
              'MMMM'
            );
            const dateVal = moment(calendar.getDate().toDate()).format('YYYY');

            dateShow.value = i18n.$t(middleValue) + ' ' + dateVal;
          }
          break;
        default:
          dateShow.value = i18n.$t(dateMonthStart) + ' ' + dateYearStart;
      }
      scrollToCurrentTime();

      selectDate(value);
    }

    const calendarMode = ref('month');

    const calendarModeSelectWeek = [
      { value: 'week', label: i18n.$t('1 Week') },
      { value: 'week_2', label: i18n.$t('2 Week') },
      { value: 'work_week', label: i18n.$t('Work Week') }
    ];

    function onClickView(value) {
      calendarMode.value = value;
      onChangeView();
    }
    function onChangeView() {
      const firstDay = moment(calendar.getDate().toDate()).format('01-MM-YYYY');

      switch (calendarMode.value) {
        case 'day':
          selectedView.value = 'day';
          calendar.setDate(new Date(focusedDate));
          calendar.changeView('day', true);
          break;
        case 'week':
          selectedView.value = 'week' + 1;
          calendar.setDate(new Date(focusedDate));
          calendar.setOptions(
            { month: { visibleWeeksCount: 1, workweek: false } },
            true
          );
          calendar.changeView('week', true);
          calendar.setOptions({ month: { workweek: false } }, true);

          break;
        case 'week_2':
          selectedView.value = 'week' + 2;
          calendar.setDate(new Date(focusedDate));
          calendar.setOptions(
            { month: { visibleWeeksCount: 2, workweek: false } },
            true
          );
          calendar.changeView('month', true);
          break;
        case 'work_week':
          selectedView.value = 'week' + 1;
          calendar.setDate(new Date(focusedDate));
          calendar.setOptions(
            { month: { visibleWeeksCount: 1, workweek: true } },
            true
          );
          calendar.changeView('month', true);
          break;
        case 'month':
          selectedView.value = 'month';
          calendar.setDate(new Date(focusedDate));
          calendar.setOptions(
            { month: { visibleWeeksCount: 0, workweek: false } },
            true
          );
          calendar.changeView('month', true);
      }
      getDateShow();
      pastDays();
    }

    async function addSchedule(val) {
      showRDVForm.value = false;
      await store.commit(SET_ADD_EVENT, val);
      const items = store.getters[GET_CALENDAR_EVENTS];
      calendar.clear();
      create(items);
      setTimeout(() => {
        pastDays();
      }, 100);
      deleteSelected();
      const filtered = updateFilterService(filters.value.service, items);
      updateFilterEmployee(filters.value.employee, filtered);
    }

    async function updateSchedule(val) {
      await store.dispatch(FETCH_EVENTS, {
        userId: userData.value.id,
        type: 'All'
      });
      showRDVForm.value = false;
      const items = store.getters[GET_CALENDAR_EVENTS];
      calendar.clear();
      create(items);
      setTimeout(() => {
        pastDays();
      }, 100);
      deleteSelected();
      const filtered = updateFilterService(filters.value.service, items);
      updateFilterEmployee(filters.value.employee, filtered);
    }

    const first_day = ref(null);

    const heightLine = ref(0);

    function scrollToCurrentTime() {
      setTimeout(() => {
        let line = document.querySelector(
          '.tui-full-calendar-timegrid-hourmarker-line-today'
        );
        let el = document.querySelector(
          '.tui-full-calendar-timegrid-container'
        );
        if (el) {
          el.scrollTop = 0;
        }
        if (line) {
          const height = line.getBoundingClientRect().top;

          el.scrollTop = height - window.innerHeight / 2 - 100;
          heightLine.value = height;
        }
      }, 50);
    }

    getFirstDay();

    async function getFirstDay() {
      await axios.get(`setting?owner_id=${userData.value.id}`).then((res) => {
        let item = res.data.data.items.filter((el) => el.type == 'first_day');
        if (item.length > 0) {
          first_day.value = item[0].value + 1;
        } else {
          first_day.value = 1;
        }
      });
    }

    // const newSchedules = ref(schedules);

    function updateFilterService(e, items) {
      let arr = [];
      if (e == null) {
        arr = items;
      } else if (e.length > 0) {
        items.forEach((el) => {
          if (e.includes(el.service_id)) {
            arr.push(el);
          }
        });
      } else {
        arr = [];
      }

      calendar.clear();
      create(arr);
      setTimeout(() => {
        pastDays();
      }, 100);
      return arr;
    }

    function updateFilterEmployee(e, items) {
      let arr = [];
      if (e == null) {
        arr = items;
      } else if (e.length > 0) {
        items.forEach((el) => {
          if (e.includes(el.__employee_id)) {
            arr.push(el);
          }
        });
      } else {
        arr = [];
      }

      calendar.clear();
      create(arr);
      setTimeout(() => {
        pastDays();
      }, 100);
      return arr;
    }

    return {
      i18n,
      blockUI,
      selectedView,
      deleteSelected,
      contacts,
      showRDVForm,
      showRDVView,
      selectedEvent,
      sideBarReduced,
      dateShow,
      onShowRDVForm,
      openEditEvent,
      onDateChange,
      onPrevPeriod,
      onNextPeriod,
      onClickTodayBtn,
      onChangeView,
      onClickView,
      addSchedule,
      updateSchedule,
      selectedDate,
      first_day,
      calendarMode,
      calendarModeSelectWeek
    };
  }
};
</script>

<style lang="scss" scoped>
.calendar-date-right {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.calendar-date-absolute {
  position: absolute;
  left: 10%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.rdv-calendar {
  display: flex;
}
.event-calendar {
  width: 100%;
}
.calendar-header {
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.calendar {
  width: 100%;
  height: calc(100% - 80px);
}
.calendar .tui-full-calendar-section-button {
  display: none !important;
}
.calendar-detail-item small {
  text-transform: capitalize;
}

.move-month {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:focus {
    outline: none;
  }
}
::v-deep(.p-dialog-header) {
  display: none !important;
}
::v-deep(.p-dialog-content) {
  padding: 0;
}

.tui-datepicker {
  left: unset !important;
}
.calendar-header-btn,
.calendar-header-btn:hover,
.calendar-header-btn:active {
  background: none;
  color: #2196f3;
  border: none;
}
@media (max-width: 620px) {
  .calendar-header-btn {
    padding: 4px;
    margin: 2px;
  }
}
.calendar-types {
  width: 120px;
}
@media (max-width: 390px) {
  .p-button.p-button-icon-only.p-button-rounded {
    height: 1.5rem;
    width: 1.5rem;
  }
  .p-button.p-button-icon-only.p-button-rounded h5 {
    font-size: 12px;
  }
  .calendar-header-btn {
    font-size: 10px;
  }
}
</style>
<style>
.tui-datepicker {
  left: unset !important;
}
.transparent {
  background: transparent;
}
.transparent.card {
  padding-bottom: 0;
  margin-bottom: 0;
}
.calendar-body {
  background: linear-gradient(to bottom, #f3f4f9 0%, #d7dbe8 100%);
  overflow: scroll;
}
.tui-full-calendar-weekday-schedule-bullet {
  display: none;
}
.selected {
  border: 1px solid #515ce6 !important;
  background-color: rgba(81, 92, 230, 0.05) !important;
  height: 99%;
}
</style>