<template>
  <p class="text-sm" v-if="maxLength == 0">
    {{ i18n.$t('No available options') }}
  </p>
  <table v-else>
    <tr>
      <th @click="previousDays()"><i class="pi pi-angle-left"></i></th>
      <th v-for="day of days" :key="day.dayNumber">
        {{ day.dayName }}
        <br />
        <span class="p-text-xs p-text-gray-400">
          {{ day.dayNumber }}
          {{ day.month }}
        </span>
      </th>

      <th @click="nextDays()"><i class="pi pi-angle-right"></i></th>
    </tr>
    <tr v-if="isLoading" class="text-center">
      <td colspan="6">
        <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration=".5s"
        />
      </td>
    </tr>
    <tr v-else v-for="i in maxLength" :key="i" class="text-center">
      <td></td>
      <td v-for="day of days" :key="day.dayNumber">
        <Button
          :label="day.slots[i - 1]['start']"
          class="p-button-raised p-button-rounded"
          :class="{
            'p-button-outlined': !isSelected(
              day.slots[i - 1]['date'] + ' ' + day.slots[i - 1]['start']
            )
          }"
          v-if="day.slots[i - 1] && day.slots[i - 1]['availability'] == 1"
          @click="
            selectDate(
              day.slots[i - 1]['date'] + ' ' + day.slots[i - 1]['start'],
              day.slots[i - 1]['duration']
            )
          "
        />
        <Button
          :label="day.slots[i - 1]['start']"
          class="p-button-raised p-button-rounded"
          :class="{
            'p-button-outlined': !isSelected(
              day.slots[i - 1]['date'] + ' ' + day.slots[i - 1]['start']
            )
          }"
          :disabled="true"
          v-if="day.slots[i - 1] && day.slots[i - 1]['availability'] == 0"
        />
        <span v-if="!!!day.slots[i - 1]">_</span>
      </td>
      <td></td>
    </tr>
  </table>
</template>

<script>
import moment from 'moment';
import { useI18n } from '@/plugins/i18nPlugin';
import axios from '@/plugins/axios';
import { computed, ref, watch } from '@vue/runtime-core';

export default {
  props: {
    event: {
      type: Object,
      default: () => null
    }
  },
  setup(props) {
    const i18n = useI18n();
    const maxLength = ref(0);
    const isLoading = ref(false);
    const selectedDate = ref(null);

    let days = ref([]);
    let mainDate = props.date;
    if (props.event.date instanceof Date) {
      mainDate = moment.utc(props.event.date);
      //console.log(props.event.date);
    }
    let date = new moment(mainDate.format());
    const dayLabels = i18n.$t('smallDays');
    const monthLabels = [
      i18n.$t('January').substring(0, 3),
      i18n.$t('February').substring(0, 3),
      i18n.$t('March').substring(0, 3),
      i18n.$t('April').substring(0, 3),
      i18n.$t('May').substring(0, 3),
      i18n.$t('June').substring(0, 4),
      i18n.$t('July').substring(0, 4),
      i18n.$t('August').substring(0, 3),
      i18n.$t('September').substring(0, 4),
      i18n.$t('October').substring(0, 3),
      i18n.$t('November').substring(0, 3),
      i18n.$t('December').substring(0, 3)
    ];
    console.log(monthLabels);
    const slots = ref([]);
    for (let c = 0; c < 4; c++) {
      const dayObj = {
        dayName: dayLabels[date.isoWeekday() - 1],
        month: monthLabels[date.format('M') - 1],
        dayNumber: date.format('DD'),
        slots: []
      };
      days.value.push(dayObj);
      date = date.add(1, 'days');
    }
    async function loadSlots() {
      if (props.event.service_id == null) {
        slots.value = [];
        return;
      }
      isLoading.value = true;
      const res = await axios.get(
        'service/slots/' +
          props.event.service_id +
          '?date=' +
          mainDate.format('YYYY-MM-DD')
      );
      slots.value = res.data.data.items;
      slots.value.forEach((slot) => {
        if (
          mainDate.format('YYYY-MM-DD HH:mm') ==
          slot.date + ' ' + slot.start
        ) {
          selectedDate.value = mainDate.format('YYYY-MM-DD HH:mm');
        }
      });
      maxLength.value = 0;
      days.value = [];
      date = new moment(mainDate.format());
      for (let c = 0; c < 4; c++) {
        const dayObj = {
          dayName: dayLabels[date.isoWeekday() - 1],
          month: monthLabels[date.format('M') - 1],
          dayNumber: date.format('DD'),
          slots: slots.value.filter((item) => {
            return date.format('d') == item.day;
          })
        };
        maxLength.value =
          dayObj.slots.length > maxLength.value
            ? dayObj.slots.length
            : maxLength.value;

        days.value.push(dayObj);
        date = date.add(1, 'days');
      }
      isLoading.value = false;
    }
    loadSlots();
    function selectDate(date, duration) {
      selectedDate.value = date;
      props.event.date = new moment.utc(date, 'YYYY-MM-DD HH:mm').toDate();
      props.event.duration = duration;
    }

    function isSelected(date) {
      return selectedDate.value == date;
    }

    function previousDays() {
      mainDate = mainDate.subtract(4, 'days');
      loadSlots();
    }
    function nextDays() {
      mainDate = mainDate.add(4, 'days');
      loadSlots();
    }

    watch(
      () => [props.event.service_id],
      () => {
        loadSlots();
      }
    );
    return {
      i18n,
      days,
      slots,
      maxLength,
      selectDate,
      isSelected,
      previousDays,
      nextDays,
      isLoading
    };
  }
};
</script>

<style scoped>
table {
  width: 100%;
}
i.pi {
  font-size: 2rem;
}
</style>