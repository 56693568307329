import {
    UserDataInfo
} from '@/plugins/api'

const userData = new UserDataInfo();
export default class ContactModel {
    constructor(item) {
        this.owner_id = userData.ownerId();
        if (item) {
            this._id = item._id ? item._id : null;
            this.first_name = item.first_name ? item.first_name : null;
            this.last_name = item.last_name ? item.last_name : null;
            this.prefix = item.prefix ? item.prefix : null;
            this.phone = item.phone ? item.phone : null;
            this.email = item.email ? item.email : null;
        }
    }
}