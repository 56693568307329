<template>
  <div class="p-timepicker">
    <div class="p-hour-picker">
      <button
        class="p-link"
        type="button"
        @click="
          hours < 24 ? hours++ : null,
            $emit('update', { hours, minutes }, toInput())
        "
      >
        <span class="pi pi-chevron-up"></span>
      </button>
      <input
        v-model="inputHours"
        type="number"
        max="24"
        min="0"
        @change="$emit('update', { hours, minutes })"
        @blur="updateHours(), $emit('update', { hours, minutes }), toInput()"
      />
      <button
        class="p-link"
        type="button"
        @click="
          hours !== 0 ? hours-- : null,
            $emit('update', { hours, minutes }, toInput())
        "
      >
        <span class="pi pi-chevron-down"></span>
      </button>
    </div>
    <div class="p-separator"><span>:</span></div>
    <div class="p-minute-picker">
      <button
        class="p-link"
        type="button"
        @click="
          minutes < 60 ? minutes++ : null,
            $emit('update', { hours, minutes }, toInput())
        "
      >
        <span class="pi pi-chevron-up"></span>
      </button>
      <input
        v-model="inputMin"
        type="number"
        max="60"
        min="0"
        @change="$emit('update', { hours, minutes })"
        @blur="updateMinutes(), $emit('update', { hours, minutes }), toInput()"
      />
      <button
        class="p-link"
        type="button"
        @click="
          minutes !== 0 ? minutes-- : null,
            $emit('update', { hours, minutes }),
            toInput()
        "
      >
        <span class="pi pi-chevron-down"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: ['modelValue'],
  setup(props, { emit }) {
    const minutes = ref(0);
    const hours = ref(0);
    const inputMin = ref('00');
    const inputHours = ref('00');
    if (props.modelValue != null) {
      minutes.value = +props.modelValue.getMinutes();
      inputMin.value = props.modelValue.getMinutes() + '';
      hours.value = +props.modelValue.getHours();
      inputHours.value = props.modelValue.getHours() + '';
    }
    function updateHours() {
      updateLogic('hours', inputHours, hours);
    }

    function updateMinutes() {
      updateLogic('min', inputMin, minutes);
    }

    function updateLogic(type, value, item) {
      if (value.value[0] == '0' || !value.value[1]) {
        const parse = !value.value[1]
          ? parseInt(value.value[0])
          : parseInt(value.value[1]);
        item.value = parse < 0 ? (item.value = 0) : parse;
      } else {
        const parse = parseInt(value.value);
        const maxValue = type == 'min' ? 59 : 23;
        item.value = parse > maxValue ? maxValue : parse;
      }
    }

    function toInput() {
      inputMin.value =
        minutes.value < 10 ? `0${minutes.value}` : `${minutes.value}`;
      inputHours.value =
        hours.value < 10 ? `0${hours.value}` : `${hours.value}`;
    }

    return {
      minutes,
      hours,
      updateHours,
      updateMinutes,
      inputMin,
      inputHours,
      toInput
    };
  }
};
</script>

<style lang="scss" scoped>
.p-timepicker {
  height: 60px;
  & > * {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.p-separator {
      justify-content: center;
    }
  }
}
input {
  width: 42px;
  font-size: 17px;
  text-align: center;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>